import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService, getHeadersFormUrlEncoded } from '@sbt-suite/components';
import * as CryptoJS from 'crypto-js';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { buildUrl } from 'src/app/shared/functions';
import { environment } from 'src/environments/environment';
import { RsaService } from '..';
import { AUTH_AZURE, AUTH_EXTERNAL_USER } from '../../constants/autenticacao';
import { LINKS_APLICACOES } from '../../constants/links-aplicacoes';
import {
    IAutenticacaoService,
    IResponseAuthLogin,
    IResponseMfa,
    IToken
} from '../../models/autenticacao.model';
import { LocalStorageService } from '../localStorage/local-storage.service';
import { AzureAutenticacaoService } from './azure-autenticacao.service';
import { UsuarioExternoAutenticacaoService } from './usuario-externo-autenticacao.service';

@Injectable({
    providedIn: 'root'
})
export class AutenticacaoService {
    URL_BASE = environment.URL_BASE + environment.PATH;
    MODE = environment.MODE;
    PORT_CORE = environment.PORT_CORE;

    private serviceSelecionado!: string;
    private _dadosUsuarioSessao!: any;
    private _usuarioAutenticado$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(
        private http: HttpClient,
        private localStorageService: LocalStorageService,
        private rsa: RsaService,
        private _cookie: CookieService
    ) {
        this.setUsuarioAutenticado(this.checkAcessToken());
    }

    setServiceAutenticacao(opcao: string): void {
        this.serviceSelecionado = opcao;
        this.localStorageService.setItem('serviceAuth', opcao);
    }

    getServiceAutenticacao(): IAutenticacaoService {
        switch (this.serviceSelecionado) {
            case AUTH_AZURE:
                return new AzureAutenticacaoService(this.http, this.localStorageService);
            case AUTH_EXTERNAL_USER:
                return new UsuarioExternoAutenticacaoService(this.http, this.rsa);
            default:
                throw new Error('Opção inválida de autenticação');
        }
    }

    getServiceSelecionado() {
        this.serviceSelecionado = this.localStorageService.getItem('serviceAuth');
    }

    validarCodigoMfa(codigo: string): Promise<IResponseMfa> {
        return lastValueFrom(
            this.http.get<IResponseMfa>(
                buildUrl(this.URL_BASE, `/api/v1/mfa/${codigo}`, this.PORT_CORE)
            )
        );
    }

    setTokenUsuario(token: string, refreshToken: string) {
        const currentDate = new Date();
        const expireDate = new Date(currentDate.getTime());
        expireDate.setDate(expireDate.getDate() + 5);

        let domain = '';
        let secure = false;
        let httpOnly = false;

        if (this.MODE === 'dev' || this.MODE === 'prod') {
            domain = 'tvsbt.com.br';
            secure = true;
        }

        this._cookie.set(
            environment.ACCESS_TOKEN,
            token,
            expireDate,
            '/',
            domain,
            secure,
            httpOnly
        );
        this._cookie.set(
            environment.REFRESH_TOKEN,
            refreshToken,
            expireDate,
            '/',
            domain,
            secure,
            httpOnly
        );
    }

    setUsuarioAutenticado(autenticado: boolean) {
        this._usuarioAutenticado$.next(autenticado);
    }

    clearCookie() {
        let domain = '';
        if (this.MODE == 'dev' || this.MODE == 'prod') {
            domain = 'tvsbt.com.br';
        }
        this._cookie.delete(environment.ACCESS_TOKEN, '/', domain);
        this._cookie.delete(environment.REFRESH_TOKEN, '/', domain);
    }

    /** redireciona de volta para aplicação de origem */
    redirectBackApplication(): string {
        const app = this.localStorageService.getItem('redirectBack');
        if (app && LINKS_APLICACOES[this.MODE][app]) {
            const route = LINKS_APLICACOES[this.MODE][app];
            return route;
        }

        return '';
    }

    checkUsuarioAutenticado(): Observable<boolean> {
        return this._usuarioAutenticado$.asObservable();
    }

    /**
     * verifica se o usuario esta autenticado
     * se tiver retorna um "true" e permite entrar nas páginas protegidas
     * @returns returna um Observable
     */
    getUsuarioAutenticado() {
        const token = this._cookie.get(environment.ACCESS_TOKEN);
        if (token) {
            const decodedToken: IToken = this._decodeToken(token);
            this._dadosUsuarioSessao = decodedToken;
            return this._dadosUsuarioSessao;
        }
        return null;
    }

    checkIsUserAdminCore() {
        const roles = this._dadosUsuarioSessao.realm_access.roles;
        return roles.some((role: string) => role === 'admin');
    }

    /**
     * verifica se existe o "access_token" de sessão
     * se tiver retorna um "true" e permite entrar nas páginas protegidas
     * @returns returna um booleano
     */
    checkAcessToken() {
        return !!this._cookie.get(environment.ACCESS_TOKEN);
    }

    refreshToken(token: string) {
        const params = new HttpParams()
            .set('refresh_token', token)
            .set('grant_type', 'refresh_token')
            .set('aplicacao', 'app-login');

        return this.http.post<IResponseAuthLogin>(
            `${this.URL_BASE}/api/v1/login`,
            params.toString(),
            {
                headers: getHeadersFormUrlEncoded()
            }
        );
    }

    private _decodeToken(token: string) {
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            const decodedToken = JSON.parse(
                CryptoJS.enc.Base64.parse(base64).toString(CryptoJS.enc.Utf8)
            );
            return decodedToken;
        } catch (error) {
            console.error('Erro ao decodificar o token:', error);
        }
    }
}
