export const environment = {
    production: false,
    PATH: '/login',
    URL_BASE: 'https://appdev.tvsbt.com.br/suite',
    URL_LOGIN: 'https://login-h.tvsbt.com.br/',
    ACCESS_TOKEN: 'dev_access_token',
    REFRESH_TOKEN: 'dev_refresh_token',
    PORT_LOGIN: '',
    PORT_CORE: '',
    MODE: 'dev',
    URL_CORE: 'https://core-h.tvsbt.com.br',
    AZURE_CLIENT_ID: 'app-login',
    AZURE_CLIENT_SECRET: 'J0oxgBwo8ToLgcJqlFe22jWTEnS6CtV3',
    AZURE_REDIRECT_URI: 'https://login-h.tvsbt.com.br/check-autenticacao',
    URL_OPEN_ID: 'https://suiteh-keycloak.tvsbt.com.br/realms/master/protocol/openid-connect/auth',
    LOCAL_STORAGE_KEY: '16b350b6e1b3e7f4aa24ebf1e57a1eab',
    LOCAL_STORAGE_IV: '0000000000000000'
};
