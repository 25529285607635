import { HttpClient } from '@angular/common/http';
import { getHeadersFormUrlEncoded } from '@sbt-suite/components';
import * as CryptoJS from 'crypto-js';
import { lastValueFrom } from 'rxjs';
import { buildUrl } from 'src/app/shared/functions';
import { environment } from 'src/environments/environment';
import { CODE_CHALLENGE_METHOD, GRANT_TYPE_CODE } from '../../constants/autenticacao';
import { IAutenticacaoAzureService, IResponseAuthLogin } from '../../models';
import { LocalStorageService } from '../localStorage/local-storage.service';

export class AzureAutenticacaoService implements IAutenticacaoAzureService {
    URL_BASE = environment.URL_BASE + environment.PATH;
    PORT_CORE = environment.PORT_CORE;

    AZURE_CLIENT_ID = environment.AZURE_CLIENT_ID;
    AZURE_REDIRECT_URI = environment.AZURE_REDIRECT_URI;
    AZURE_CLIENT_SECRET = environment.AZURE_CLIENT_SECRET;
    URL_OPEN_ID = environment.URL_OPEN_ID;

    constructor(private http: HttpClient, private localStorageService: LocalStorageService) {}

    async login(): Promise<void> {
        await this._abrirAutenticacaoAzure();
    }

    logout(): Promise<any> {
        return lastValueFrom(this.http.post(buildUrl(this.URL_BASE, '/api/v1/logout'), {}));
    }

    checkCredenciais(code: string): Promise<IResponseAuthLogin> {
        const codeVerifier = this.localStorageService.getItem('code_verifier') as string;
        const codeChallenge = this.localStorageService.getItem('code_challenge') as string;

        if (!codeVerifier || !codeChallenge) throw new Error('Parâmetros inválidos');

        const params = new URLSearchParams();
        params.set('client_id', this.AZURE_CLIENT_ID);
        params.set('redirect_uri', this.AZURE_REDIRECT_URI);
        params.set('grant_type', GRANT_TYPE_CODE);
        params.set('code', code);
        params.set('code_verifier', codeVerifier);
        params.set('code_challenge', codeChallenge);
        params.set('code_challenge_method', CODE_CHALLENGE_METHOD);
        //TODO: 'client_secret' será removido do front
        params.set('client_secret', this.AZURE_CLIENT_SECRET);

        return lastValueFrom(
            this.http.post<IResponseAuthLogin>(
                buildUrl(this.URL_BASE, '/api/v1/login', this.PORT_CORE),
                params,
                {
                    headers: getHeadersFormUrlEncoded()
                }
            )
        );
    }

    private async _abrirAutenticacaoAzure(): Promise<void> {
        const { codeChallenge, state } = this._gerarCodeChallengeVerifier();
        const params = new URLSearchParams();
        params.set('client_id', this.AZURE_CLIENT_ID);
        params.set('code_challenge', codeChallenge);
        params.set('code_challenge_method', CODE_CHALLENGE_METHOD);
        params.set('response_type', 'code');
        params.set('redirect_uri', this.AZURE_REDIRECT_URI);
        params.set('scope', 'openid');
        params.set('state', state);
        params.set('response_mode', 'query');
        params.set('kc_idp_hint', 'azure');

        window.open(`${this.URL_OPEN_ID}?${params.toString()}`, '_self');
    }

    private _gerarStrRandom(tamanho: number) {
        let resultado = '';
        const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const tamanhoCaracteres = caracteres.length;
        for (let i = 0; i < tamanho; i++) {
            resultado += caracteres.charAt(Math.floor(Math.random() * tamanhoCaracteres));
        }
        return resultado;
    }

    private _gerarCodeChallengeVerifier() {
        const state = this._gerarStrRandom(40);
        const codeVerifier = this._gerarStrRandom(128);

        const codeVerifierHash = CryptoJS.SHA256(codeVerifier).toString(CryptoJS.enc.Base64);

        const codeChallenge = codeVerifierHash
            .replace(/=/g, '')
            .replace(/\+/g, '-')
            .replace(/\//g, '_');

        this.localStorageService.setItem('state', state);
        this.localStorageService.setItem('code_challenge', codeChallenge);
        this.localStorageService.setItem('code_verifier', codeVerifier);

        return { codeChallenge, codeVerifier, state };
    }
}
